import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/Layout"
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import PostMeta from "../../components/PostMeta"
import get from 'lodash/get'
import PostCategories from "../../components/PostCategories"
import DateIcon from "../../assets/svg/date.inline.svg"

class Post extends React.Component {
  render() { 

  const allWpPost = get(this, 'props.data.allWpPost')
  const page = get(this, 'props.data.page')

  return (
    <Layout
      bodyClass={`post-template-default single single-post postid-${page.databaseId} single-format-standard wp-embed-responsive singular has-post-thumbnail has-single-pagination showing-comments footer-top-visible customize-support`}
    >
      <GatsbySeo 
        title={page.seo.title} description={page.seo.metaDesc} 
        canonical={`https://www.nettikasinolla.com/${page.slug}/`}
      />
      <article
        className={`post-${page.databaseId} post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized`}
        id={`post-${page.databaseId}`}
      >
        <header className="entry-header has-text-align-center header-footer-group">
          {page.featuredImage !== null &&
            <img className="featuredImg" src={page.featuredImage.node.mediaItemUrl} width="1000"/>
          }
          <h1
            className="entry-title"
            dangerouslySetInnerHTML={{ __html: page.title }}
          />
        </header>
        <div className="post-inner thin">
          <div className="post-meta">
            <PostMeta title={page.title} author={page.author} date={page.date} />
            <PostCategories categories={page.categories} />
          </div>
          <div
            className="entry-content"
            dangerouslySetInnerHTML={{ __html: page.content }}
          />
        </div>
      </article>
      <h4 className="related-header">Voisit olla kiinnostunut myös:</h4>
      <div className="related">
      {allWpPost.edges.map(({node})=> (
       <article key={node.databaseId} className={`post-${node.databaseId} post type-post has-post-thumbnai archive-post`} id={`post-${node.databaseId}`}>
        <header className="entry-header">
            {node.featuredImage !== null &&
              <img src={node.featuredImage.node.mediaItemUrl} className="featured" alt={node.featuredImage.node.altText}/>
            }
            <h5 className="entry-title heading-size-5">
              <Link to={node.uri}
                dangerouslySetInnerHTML={{ __html: node.title }}
              />
            </h5>
            <span className="meta-date"><DateIcon /> {node.date}</span>
        </header>
      </article>
      ))}
      </div>
    </Layout>
  )
}
}

export default Post

export const query = graphql`
  query post($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPost(id: { eq: $id }) {
      title
      content
      databaseId
      date(formatString: "DD.MM.YYYY")
      seo {
        metaDesc
        title
      }
      slug
      featuredImage {
        node {
          id
          mediaItemUrl
        }
      }
    }
    nextPage: wpPost(id: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpPost(id: { eq: $previousPage }) {
      title
      uri
    }
    allWpPost(filter: {id: {ne: $id}}, limit: 3) {
      edges {
        node {
          id
          title
          content
          databaseId
          date (formatString: "DD.MM.YYYY")
          excerpt
          seo {
            metaDesc
            title
          }
          uri
          featuredImage {
            node {
              id
              mediaItemUrl
              altText
            }
          }
        }
      }
    }
  }
`
